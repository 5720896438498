import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
  <>
    <SEO title="Bataille de Varsovie en 1920" lang="fr" description="L'histoire de la bataille la plus importante dont vous n'avez probablement jamais entendu parler." />
    <Intro locale="fr" />
  </>
);
export default Index;
